import React from "react";

export const Footer = () => {
  return (
    <footer
      style={{
        position: "absolute",
        bottom: "0",
        width: "100%",
        zIndex: "10000",
        // fontSize: "2vh",
        color: "#ffffb3",
        textAlign: "center",
        fontFamily: "Poppins",
        fontWeight: "600",
        fontSize: "1.5vh",
        color: "#ffffb3",
      }}
    >
      <p>
        Designed & Developed By{" "}
        <a
          style={{ color: "#ffffb3" }}
          href="https://www.tagbin.in/"
          target="_blank"
        >
          Tagbin
        </a>
      </p>
    </footer>
  );
};
