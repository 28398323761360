import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: {
      shaloks: [
        {
          id: 1,
          ques: "Why is it important for humans to perform duties? ",
          ans: "Shri Krishna says that the one who turns away from his duties is called a coward and loses his self-respect. For a respectful person, self-prestige is very important. For them, dishonour is worse than death. Therefore, it is important for a human being to perform their duties. ",
          verse:
            "अकीर्तिं चापि भूतानि कथयिष्यन्ति तेऽव्ययाम् |\nसम्भावितस्य चाकीर्ति र्मरणादतिरिच्यते।। 34।।",
        },
        {
          id: 2,
          ques: "If everything in this world is pre-destined, why should I indulge in work?",
          ans: "Shri Krishna says that humans are bound by their deeds. They are bound to perform their duties. The ones who perform their duties earn self-respect and gain prestige in society. Those who flee their responsibilities, on the other hand, face defamation and, as a result, lose self-esteem. ",
          verse:
            "अथ चेतत्त्वमिमं धर्म्यं संग्रामं न करिष्यसि |\nतत: स्वधर्मं कीर्तिं च हित्वा पापमवाप्स्यसि।। 33।।",
        },
        {
          id: 3,
          ques: "My failures lead me to feel a sense of guilt and incompetence. How can I overcome such a painful feeling?",
          ans: "Shri Krishna says that humans have to perform their duties only for the sake of duty, without getting attached to them. If you stay indifferent to happiness and distress, loss and gain, victory and defeat, and success and failure, you will not encounter such painful feelings. By fulfilling your responsibilities, you will never incur guilt. ",
          verse:
            "सुखदु:खे समे कृत्वा लाभालाभौ जयाजयौ।\nततो युद्धाय युज्यस्व नैवं पापमवाप्स्यसि।।38।।",
        },
        {
          id: 4,
          ques: "Despite all my efforts, I face failure. How can I overcome such pressure?",
          ans: "Shri Krishna says that humans have to perform their prescribed duties, no matter what result they may get. You have authority to control your deeds and your duties. But you cannot control the outcome of your deeds. So, perform your actions for the sake of your duties without getting attached to them. If you give up the pride of doership, success or failure will not affect your mind. Thus, you can remain indifferent to success and failure.",
          verse:
            "कर्मण्येवाधिकारस्ते मा फलेषु कदाचन।\nमा कर्मफलहेतुर्भूर्मा ते सङ्गोऽस्त्वकर्मणि।।47।।",
        },
        {
          id: 5,
          ques: "How can we perform skillfully without becoming attached to our actions or their outcomes?",
          ans: "Shri Krishna says that Karmayog is the art of working skillfully with proper consciousness. One who prudently practises the science of work without getting attached can get rid of both good and bad reactions in this life. Working without personal attachment increases the quality of our work, and we become more skilled than before. It happens this way because, in this situation, we are more focused on our duties and care less about the outcome. ",
          verse:
            "बुद्धियुक्तो जहातीह उभे सुकृतदुष्कृते।\nतस्माद्योगाय युज्यस्व योग: कर्मसु कौशलम्।।50।।",
        },
        {
          id: 6,
          ques: "How can we perform our duties without getting attached to the results?",
          ans: "Shri Krishna says that Karmayog is the art of working skillfully with proper consciousness. One who prudently practises the science of work without getting attached can get rid of both good and bad reactions in this life. Working without personal attachment increases the quality of our work, and we become more skilled than before. It happens this way because, in this situation, we are more focused on our duties and care less about the outcome. ",
          verse:
            " बुद्धियुक्तो जहातीह उभे सुकृतदुष्कृते।\nतस्माद्योगाय युज्यस्व योग: कर्मसु कौशलम्।।50।।",
        },
        {
          id: 7,
          ques: "What is Karmayog?",
          ans: "Shri Krishna says that Karmayog is the art of working skillfully with proper consciousness. One who prudently practises the science of work without getting attached can get rid of both good and bad reactions in this life. Working without personal attachment increases the quality of our work, and we become more skilled than before. It happens this way because, in this situation, we are more focused on our duties and care less about the outcome. ",
          verse:
            " बुद्धियुक्तो जहातीह उभे सुकृतदुष्कृते।\nतस्माद्योगाय युज्यस्व योग: कर्मसु कौशलम्।।50।।",
        },
        {
          id: 8,
          ques: "My family died in the pandemic. How do I cope with the grieving situation?",
          ans: "Shri Krishna says that nothing in this world is worthy of grief. The wise never lament, neither for the living nor for the dead, because they accept all circumstances as God’s grace. They know that nothing in this world is permanent. They know that the one who is born will certainly die and be reborn.",
          verse:
            "अशोच्यानन्वशोचस्त्वं प्रज्ञावादांश्च भाषसे।\nगतासूनगतासूंश्च नानुशोचन्ति पण्डिता:।।11।। ",
        },
        {
          id: 9,
          ques: "How do I cope with the loss of a loved one?",
          ans: "Shri Krishna says that nothing in this world is worthy of grief. The wise never lament, neither for the living nor for the dead, because they accept all circumstances as God’s grace. They know that nothing in this world is permanent. They know that the one who is born will certainly die and be reborn.",
          verse:
            "अशोच्यानन्वशोचस्त्वं प्रज्ञावादांश्च भाषसे।\nगतासूनगतासूंश्च नानुशोचन्ति पण्डिता:।।11।। ",
        },
        {
          id: 10,
          ques: "How can I overcome my grief?",
          ans: "Shri Krishna says that nothing in this world is worthy of grief. The wise never lament, neither for the living nor for the dead, because they accept all circumstances as God’s grace. They know that nothing in this world is permanent. They know that the one who is born will certainly die and be reborn.",
          verse:
            "अशोच्यानन्वशोचस्त्वं प्रज्ञावादांश्च भाषसे।\nगतासूनगतासूंश्च नानुशोचन्ति पण्डिता:।।11।। ",
        },
        {
          id: 11,
          ques: "How can I overcome my worries?",
          ans: "Shri Krishna says that nothing in this world is worthy of grief. The wise never lament, neither for the living nor for the dead, because they accept all circumstances as God’s grace. They know that nothing in this world is permanent. They know that the one who is born will certainly die and be reborn.",
          verse:
            "अशोच्यानन्वशोचस्त्वं प्रज्ञावादांश्च भाषसे।\nगतासूनगतासूंश्च नानुशोचन्ति पण्डिता:।।11।। ",
        },
        {
          id: 12,
          ques: "If the soul is immortal, why do we grieve?",
          ans: "Shri Krishna says that we grieve because of our ignorance. Our lack of knowledge makes us think that, with the departed soul, we have lost the person. But the truth is that the soul can neither kill nor be killed.Just as a person sheds worn-out garments and wears new ones, similarly, at the time of death, the soul casts off its worn-out body and enters a new one.",
          verse:
            "य एनं वेत्ति हन्तारं यश्चैनं मन्यते हतम्।\nभौ तौ न विजानीतो नायं हन्ति न हन्यते।।19।।",
        },
        {
          id: 13,
          ques: "How can I get rid of my anger and short-temperedness?",
          ans: "Shri Krishna says that if we keep thinking about something, we get attached to it. Our attachment to such objects leads to desire, and if those desires are not fulfilled, we get angry. Therefore, to get rid of the anger, we need to stop thinking of worldly desires.",
          verse:
            "ध्यायतो विषयान्पुंस: सङ्गस्तेषूपजायते।\nसङ्गात्सञ्जायते काम: कामात्क्रोधोऽभिजायते।।62।।",
        },
        {
          id: 14,
          ques: "In today's chaotic, stressful life, how can I obtain mental peace?",
          ans: "Shri Krishna says that just as the ocean remains undisturbed by the incessant flow of waters from rivers merging into it, likewise, the person who is unmoved despite the flow of desirable objects all around them attains peace, and not the person who strives to satisfy desires. A person who lives free from a sense of greed, proprietorship, and egoism attains perfect peace.",
          verse:
            "आपूर्यमाणमचलप्रतिष्ठं समुद्रमाप: प्रविशन्ति यद्वत्।\nतद्वत्कामा यं प्रविशन्ति सर्वे स शान्तिमाप्नोति न कामकामी।।70।।",
        },
        {
          id: 15,
          ques: "It is often very difficult to focus with the mind that is very restless, turbulent, and fickle. How can we control it?",
          ans: " Shri Krishna says it is true that the human mind is very restless, hard to control. But with regular practice and detachment, one can easily control it. \n\nWe observe that the mind runs toward the objects of its attachment, toward the direction it has been habituated to running in the past. The elimination of attachment eradicates the unnecessary wanderings of the mind.",
          verse:
            "असंशयं महाबाहो मनो दुर्निग्रहं चलम्।\nअभ्यासेन तु कौन्तेय वैराग्येण च गृह्यते।।35।।",
        },
        {
          id: 16,
          ques: "Why do I feel distressed even after my accomplishments in my professional or personal life?",
          ans: "Shri Krishna says that it is our inner weakness and feeling of insecurity that bind us in a cycle of desire, greed, and hatred. On the other hand, those who neither desire nor hate anything are free from all dualities and are easily liberated from the bonds of material energy. Because they are detached from their actions and their outcomes, such Karmayogis are not distressed in any situation and thus remain content under any circumstances.",
          verse:
            "ज्ञेय: स नित्यसंन्यासी यो न द्वेष्टि न काङ्क्षति।\nनिर्द्वन्द्वो हि महाबाहो सुखं बन्धात्प्रमुच्यते।।3।।",
        },
        {
          id: 17,
          ques: "Why am I distressed even after enjoying all the worldly pleasures? ",
          ans: "Shri Krishna says, the reason behind one's distress is the domination of Tamas guna. \nThere are three types of qualities, i.e., gunas in human nature. Those are: Sattva, Rajas and Tamas.\nAmong these, Sattva is superior, as it binds us with the inner joy and happiness. The Raja guna conditions the soul towards actions. But tamas clouds our wisdom and binds one to delusion. \nOnce you remove those clouds of delusion, you can recognise your true qualities and set yourself free from all the distresses from your life.",
          verse:
            "सत्त्वं सुखे सञ्जयति रज: कर्मणि भारत।\nज्ञानमावृत्य तु तम: प्रमादे सञ्जयत्युत।।9।। (Chapter 14) ",
        },
        {
          id: 18,
          ques: "Why is it important for a leader to set a good example in the society?",
          ans: "Shri Krishna says that humanity looks for inspiration in the lives of their leaders or ideals. A leader inspires society, a parent inspires their offspring, and a teacher inspires their students by setting examples. It is their imperative responsibility, in order to build a healthy society, to set lofty examples for inspiring the rest of the population through their words, deeds, and character. When noble leaders are at the forefront, the rest of society naturally gets uplifted in morality, selflessness, and spiritual strength. But in times when there is a vacuum of principled leadership, the rest of society has no standards to pursue and slumps into self-centeredness, moral bankruptcy, and spiritual lassitude.",
          verse:
            "कर्मणैव हि संसिद्धिमास्थिता जनकादय: |\nलोकसंग्रहमेवापि सम्पश्यन्कर्तुमर्हसि ।।20।।\nयद्यदाचरति श्रेष्ठस्तत्तदेवेतरो जन: |\nस यत्प्रमाणं कुरुते लोकस्तदनुवर्तते ।।21।।",
        },
        {
          id: 19,
          ques: "Why is it important for parents to set a good example before their children?",
          ans: "Shri Krishna says that humanity looks for inspiration in the lives of their leaders or ideals. A leader inspires society, a parent inspires their offspring, and a teacher inspires their students by setting examples. It is their imperative responsibility, in order to build a healthy society, to set lofty examples for inspiring the rest of the population through their words, deeds, and character. When noble leaders are at the forefront, the rest of society naturally gets uplifted in morality, selflessness, and spiritual strength. But in times when there is a vacuum of principled leadership, the rest of society has no standards to pursue and slumps into self-centeredness, moral bankruptcy, and spiritual lassitude.",
          verse:
            "कर्मणैव हि संसिद्धिमास्थिता जनकादय: |\nलोकसंग्रहमेवापि सम्पश्यन्कर्तुमर्हसि ।।20।।\nयद्यदाचरति श्रेष्ठस्तत्तदेवेतरो जन: |\nस यत्प्रमाणं कुरुते लोकस्तदनुवर्तते ।।21।।",
        },
        {
          id: 20,
          ques: "Why is it important for a teacher to set a good example before thier students?",
          ans: "Shri Krishna says that humanity looks for inspiration in the lives of their leaders or ideals. A leader inspires society, a parent inspires their offspring, and a teacher inspires their students by setting examples. It is their imperative responsibility, in order to build a healthy society, to set lofty examples for inspiring the rest of the population through their words, deeds, and character. When noble leaders are at the forefront, the rest of society naturally gets uplifted in morality, selflessness, and spiritual strength. But in times when there is a vacuum of principled leadership, the rest of society has no standards to pursue and slumps into self-centeredness, moral bankruptcy, and spiritual lassitude.",
          verse:
            "कर्मणैव हि संसिद्धिमास्थिता जनकादय: |\n लोकसंग्रहमेवापि सम्पश्यन्कर्तुमर्हसि ।।20।।\nयद्यदाचरति श्रेष्ठस्तत्तदेवेतरो जन: |\nस यत्प्रमाणं कुरुते लोकस्तदनुवर्तते ।।21।।",
        },
        {
          id: 21,
          ques: "What is the importance of attaining knowledge in this world?",
          ans: "Shri Krishna says that knowledge has the power to purify, elevate, liberate, and unite a person with the Supreme Being. It is supremely sublime and pure. In their endeavour to attain knowledge, one needs to understand the difference between theoretical information and practical knowledge. When a person puts the acquired knowledge into practice, he only attains the knowledge in the real sense.",
          verse:
            "श्रद्धावान् लभते ज्ञानं तत्पर: संयतेन्द्रिय:।\nज्ञानं लब्ध्वा परां शान्तिमचिरेणाधिगच्छति।।39।।",
        },
        {
          id: 22,
          ques: "In this deceitful world, whom can we trust as the best friend and guide? ",
          ans: "Shri Krishna says that we are our best friend and guide. With the power of our minds, we can elevate ourselves and not degrade ourselves. Because our mind can be both a good friend and the enemy of the self, we are responsible for our own elevation or debasement.",
          verse:
            "उद्धरेदात्मनात्मानं नात्मानमवसादयेत्।\nआत्मैव ह्यात्मनो बन्धुरात्मैव रिपुरात्मन:।।5।।",
        },
        {
          id: 23,
          ques: "In the modern age, most people cheat each other. In such a situation, how can we find a trustworthy person?",
          ans: "Shri Krishna says a trustworthy person is like a Yogi, who looks at everyone with an impartial intellect, whether they are his well-wishers, friends, foes, the virtuous, or the sinners. He remains neutral among friends, enemies, and relatives and unbiased between the upright and the sinful. ",
          verse:
            "सुहृन्मित्रार्युदासीनमध्यस्थद्वेष्यबन्धुषु।\nसाधुष्वपि च पापेषु समबुद्धिर्विशिष्यते।।9।।",
        },
        {
          id: 24,
          ques: "How should we cope with the situation of communal hatred that is spreading all around in modern times?",
          ans: "Shri Krishna says that those who are really enlightened would never fight over such trivial issues. Those whose minds are fixed on God, who are completely absorbed in God, who have firm faith in Him as the ultimate goal, such people quickly reach a state from which there is no return, their sins having been washed away by the light of knowledge. \nThe truly learned, with the eyes of divine knowledge, see each species with equal vision, be it a Brahmin, a cow, an elephant, a dog, or a dog-eater. \nDivine knowledge bestows a vision different from physical sight. Endowed with knowledge, devotees see all living beings as souls that are fragments of God and are therefore divine in nature.",
          verse:
            "विद्याविनयसम्पन्ने ब्राह्मणे गवि हस्तिनि।\nशुनि चैव श्वपाके च पण्डिता: समदर्शिन:।।18।।",
        },
        {
          id: 25,
          ques: "Who is God?",
          ans: "Shri Krishna says I (the Supreme Power) am seated in the heart of all living entities. I am the beginning, middle, and end of all beings.\nHe is not far from the soul—in fact he is closer than the closest. God is seated within our soul. \nSeated inside, he grants the power of consciousness and eternality to the soul. If he were to subtract his power, our soul itself would become insentient and perish. We souls are thus eternal and sentient, not by our own power, but because the supremely sentient and eternal God is seated within, and is granting his powers to us. \nOur soul is the body of God, who is the Soul of our soul.",
          verse:
            "अहमात्मा गुडाकेश सर्वभूताशयस्थित:।\nअहमादिश्च मध्यं च भूतानामन्त एव च।।20।।",
        },
        {
          id: 26,
          ques: "Where does the soul go after it leaves the human body?",
          ans: "Shri Krishna says those who relinquish the body while remembering Me at the moment of death will come to Me.\nTherefore, whoever remembers Him at the time of death, attains Him and becomes God-like in character.",
          verse:
            "अन्तकाले च मामेव स्मरन्मुक्त्वा कलेवरम्।\nय: प्रयाति स मद्भावं याति नास्त्यत्र संशय:।।5।।",
        },
        {
          id: 27,
          ques: "Is there someone who controls the universe?",
          ans: "Shri Krishna says I am the Ultimate Supreme Truth. He is the one who is omnipresent. He is the Substratum over which this entire creation exists. He is the Creator, Sustainer, and Annihilator. Similar to the beads strung on a thread, which can move into their place, God has given individual souls the free will to act as they wish, yet their existence is bound to Him. \nBut, deluded by the Maya, which are ignorance, passion, and goodness, people in this world are unable to know Him, the imperishable and eternal.",
          verse:
            "मत्त: परतरं नान्यत्किञ्चिदस्ति धनञ्जय।\nमयि सर्वमिदं प्रोतं सूत्रे मणिगणा इव।।7।।",
        },
      ],
      home: "Home",
      aboutus: "About Us",
      detail: "Give Your Details",
      name: "Enter Name",
      gender: "Select Gender",
      male: "Male",
      female: "Female",
      other: "Others",
      age: "Enter Age",
      next: "Next",
      restart: "Restart",
      tap: "Tap and hold to speak your question here",
      ask: "Ask any question related to your life",
      ques: "Question",
      enter: "Enter your details below to continue",
      heading1:
        "GITA is an age-old source of lifestyle management that Guides us, Inspires us, and Transforms us for Action.",
      heading2:
        " The Gita is the song of the first Guru and the first councillor, Shri Krishn, who cleared the clouded thoughts of Arjun and showed him the blissful paths of Karma.",
      heading3: "Askgita.ai",
      heading4:
        "is an effort to guide the current generation and transform their lifestyle by using the golden message of the Gita.",
      heading5:
        " The app/portal not only provides practical answers to the preset questions, but it also runs on voice and/or written commands.",
      heading6: " About Us",
    },
  },
  hi: {
    translation: {
      shaloks: [
        {
          id: 1,
          ques: "मनुष्यों के लिए कर्म करना क्यों महत्त्वपूर्ण है? ",
          ans: "श्रीकृष्ण कहते हैं कि जो व्यक्ति अपने कर्मों और दायित्त्वों से भागता है उसे कायर कहा जाता है, जिससे वह अपना स्वाभिमान खो देता है। किसी भी प्रतिष्ठित व्यक्ति के लिए आत्मसम्मान अत्यधिक महत्त्वपूर्ण होता है। उनके लिए अपमान मृत्यु से भी अधिक कठोर होता है। अतः, मनुष्य के लिए अपने कर्त्तव्यों का निर्वाह करना अत्यधिक महत्त्वपूर्ण है। ",
          verse:
            "अकीर्तिं चापि भूतानि कथयिष्यन्ति तेऽव्ययाम् |\nसम्भावितस्य चाकीर्ति र्मरणादतिरिच्यते।। 34।।",
        },
        {
          id: 2,
          ques: "यदि दुनिया में सब कुछ पूर्व-निर्धारित है, तो मैं कर्म क्यों करूँ?",
          ans: "श्रीकृष्ण कहते हैं कि मनुष्य अपने कर्मों से बँधा है। वह कर्तव्यों का पालन करने के लिए बाध्य है। जो अपने कर्तव्यों का पालन करते हैं वे स्वाभिमान अर्जित करते हैं और समाज में प्रतिष्ठा प्राप्त करते हैं। दूसरी ओर जो लोग अपने दायित्त्वों से भागते हैं, उन्हें अपयश का सामना करना पड़ता है और परिणामस्वरूप, वे आत्मसम्मान खो देते हैं।",
          verse:
            "अथ चेतत्त्वमिमं धर्म्यं संग्रामं न करिष्यसि |\nतत: स्वधर्मं कीर्तिं च हित्वा पापमवाप्स्यसि।। 33।।",
        },
        {
          id: 3,
          ques: "बारबार मिलने वाली असफलताओं के कारण मुझमें अपराधबोध और अक्षमता के भाव बढ़ने लगे हैं। ऐसी पीड़ादायक भावनाओं से में कैसे मुक्ति पाऊँ?",
          ans: "श्रीकृष्ण कहते हैं कि मनुष्य को केवल कर्त्तव्य-बोध के साथ अपने कार्य करने चाहिए, बिना उन पर आसक्त हुए। यदि आप ख़ुशियों और निराशाओं, लाभ और हानि, विजय एवं पराजय, सफलता एवं असफलताओं के प्रति उदासीन रहते हैं तो आपको ऐसी पीड़ादायक भावनाएँ परेशान नहीं करेंगी। इसके बाद अपने कर्त्तव्यों का निर्वाह कर लेने पर आपको किसी प्रकार का अपराधबोध भी नहीं होगा।  ",
          verse:
            "सुखदु:खे समे कृत्वा लाभालाभौ जयाजयौ।\nततो युद्धाय युज्यस्व नैवं पापमवाप्स्यसि।।38।।",
        },
        {
          id: 4,
          ques: "सारे प्रयासों के बाद भी मुझे असफलताएँ मिलती रहती हैं। इस प्रकार के मानसिक दबाव से में कैसे मुक्ति पाऊँ?",
          ans: "श्रीकृष्ण कहते हैं कि मनुष्य को केवल कर्त्तव्य-बोध के साथ अपने कार्य करने चाहिए, बिना उन पर आसक्त हुए। यदि आप ख़ुशियों और निराशाओं, लाभ और हानि, विजय एवं पराजय, सफलता एवं असफलताओं के प्रति उदासीन रहते हैं तो आपको ऐसी पीड़ादायक भावनाएँ परेशान नहीं करेंगी। इसके बाद अपने कर्त्तव्यों का निर्वाह कर लेने पर आपको किसी प्रकार का अपराधबोध भी नहीं होगा। ",
          verse:
            "कर्मण्येवाधिकारस्ते मा फलेषु कदाचन।\nमा कर्मफलहेतुर्भूर्मा ते सङ्गोऽस्त्वकर्मणि।।47।।",
        },
        {
          id: 5,
          ques: "अपने कर्मों या उनके फल में आसक्त हुए बिना हम कुशलतापूर्वक कार्य कैसे कर सकते हैं?",
          ans: "श्रीकृष्ण कहते हैं कि कर्मयोग पूरी चेतना के साथ कुशलतापूर्वक कार्य करने की कला है। जो व्यक्ति बिना आसक्त हुए विवेकपूर्ण ढंग से कर्म के विज्ञान का निर्वाह करता है, वह अपने जीवन में अच्छी और बुरी प्रतिक्रियाओं से मुक्त हो सकता है। \nव्यक्तिगत आसक्ति के बिना कार्य करने से व्यक्ति के कार्य की गुणवत्ता बढ़ती है, और हम अपने कार्य में पहले से अधिक कुशल हो पाते हैं। ऐसा इसलिए होता है क्योंकि ऐसी स्थिति में हम अपने कर्त्तव्यों के प्रति अधिक सजग होते हैं और उनके परिणामों पर कम ध्यान देते हैं। ",
          verse:
            "बुद्धियुक्तो जहातीह उभे सुकृतदुष्कृते।\nतस्माद्योगाय युज्यस्व योग: कर्मसु कौशलम्।।50।।",
        },
        {
          id: 6,
          ques: "कर्मफल से आसक्त हुए बिना हम अपने कर्त्तव्यों का निर्वाह कैसे कर सकते हैं?",
          ans: "श्रीकृष्ण कहते हैं कि कर्मयोग पूरी चेतना के साथ कुशलतापूर्वक कार्य करने की कला है। जो व्यक्ति बिना आसक्त हुए विवेकपूर्ण ढंग से कर्म के विज्ञान का निर्वाह करता है, वह अपने जीवन में अच्छी और बुरी प्रतिक्रियाओं से मुक्त हो सकता है। \nव्यक्तिगत आसक्ति के बिना कार्य करने से व्यक्ति के कार्य की गुणवत्ता बढ़ती है, और हम अपने कार्य में पहले से अधिक कुशल हो पाते हैं। ऐसा इसलिए होता है क्योंकि ऐसी स्थिति में हम अपने कर्त्तव्यों के प्रति अधिक सजग होते हैं और उनके परिणामों पर कम ध्यान देते हैं।  ",
          verse:
            " बुद्धियुक्तो जहातीह उभे सुकृतदुष्कृते।\nतस्माद्योगाय युज्यस्व योग: कर्मसु कौशलम्।।50।।",
        },
        {
          id: 7,
          ques: "कर्मयोग क्या है?",
          ans: "श्रीकृष्ण कहते हैं कि कर्मयोग पूरी चेतना के साथ कुशलतापूर्वक कार्य करने की कला है। जो व्यक्ति बिना आसक्त हुए विवेकपूर्ण ढंग से कर्म के विज्ञान का निर्वाह करता है, वह अपने जीवन में अच्छी और बुरी प्रतिक्रियाओं से मुक्त हो सकता है। \nव्यक्तिगत आसक्ति के बिना कार्य करने से व्यक्ति के कार्य की गुणवत्ता बढ़ती है, और हम अपने कार्य में पहले से अधिक कुशल हो पाते हैं। ऐसा इसलिए होता है क्योंकि ऐसी स्थिति में हम अपने कर्त्तव्यों के प्रति अधिक सजग होते हैं और उनके परिणामों पर कम ध्यान देते हैं। ",
          verse:
            " बुद्धियुक्तो जहातीह उभे सुकृतदुष्कृते।\nतस्माद्योगाय युज्यस्व योग: कर्मसु कौशलम्।।50।।",
        },
        {
          id: 8,
          ques: "मेरा परिवार महामारी में मर गया। इस पीड़ा का सामना मैं कैसे करूँ?",
          ans: "श्रीकृष्ण कहते हैं कि इस संसार में कुछ भी शोक के योग्य नहीं है। ज्ञानी कभी शोक नहीं करते, न जीवितों के लिए और न ही मृतकों के लिए, क्योंकि वे सभी परिस्थितियों को ईश्वर की कृपा के रूप में स्वीकार करते हैं। वे जानते हैं कि इस संसार में कुछ भी स्थायी नहीं है। वे जानते हैं कि जिसका जन्म हुआ है वह निश्चित रूप से मरेगा और पुनर्जन्म लेगा।",
          verse:
            "अशोच्यानन्वशोचस्त्वं प्रज्ञावादांश्च भाषसे।\nगतासूनगतासूंश्च नानुशोचन्ति पण्डिता:।।11।। ",
        },
        {
          id: 9,
          ques: "अपने प्रियजन की मृत्यु का सामना मैं कैसे करूँ?",
          ans: "श्रीकृष्ण कहते हैं कि इस संसार में कुछ भी शोक के योग्य नहीं है। ज्ञानी कभी शोक नहीं करते, न जीवितों के लिए और न ही मृतकों के लिए, क्योंकि वे सभी परिस्थितियों को ईश्वर की कृपा के रूप में स्वीकार करते हैं। वे जानते हैं कि इस संसार में कुछ भी स्थायी नहीं है। वे जानते हैं कि जिसका जन्म हुआ है वह निश्चित रूप से मरेगा और पुनर्जन्म लेगा।",
          verse:
            "अशोच्यानन्वशोचस्त्वं प्रज्ञावादांश्च भाषसे।\nगतासूनगतासूंश्च नानुशोचन्ति पण्डिता:।।11।। ",
        },
        {
          id: 10,
          ques: "मैं अपने दु:खों से कैसे छुटकारा पाऊँ?",
          ans: "श्रीकृष्ण कहते हैं कि इस संसार में कुछ भी शोक के योग्य नहीं है। ज्ञानी कभी शोक नहीं करते, न जीवितों के लिए और न ही मृतकों के लिए, क्योंकि वे सभी परिस्थितियों को ईश्वर की कृपा के रूप में स्वीकार करते हैं। वे जानते हैं कि इस संसार में कुछ भी स्थायी नहीं है। वे जानते हैं कि जिसका जन्म हुआ है वह निश्चित रूप से मरेगा और पुनर्जन्म लेगा।",
          verse:
            "अशोच्यानन्वशोचस्त्वं प्रज्ञावादांश्च भाषसे।\nगतासूनगतासूंश्च नानुशोचन्ति पण्डिता:।।11।। ",
        },
        {
          id: 11,
          ques: "मैं अपनी चिंताओं से कैसे छुटकारा पाऊँ?",
          ans: "श्रीकृष्ण कहते हैं कि इस संसार में कुछ भी शोक के योग्य नहीं है। ज्ञानी कभी शोक नहीं करते, न जीवितों के लिए और न ही मृतकों के लिए, क्योंकि वे सभी परिस्थितियों को ईश्वर की कृपा के रूप में स्वीकार करते हैं। वे जानते हैं कि इस संसार में कुछ भी स्थायी नहीं है। वे जानते हैं कि जिसका जन्म हुआ है वह निश्चित रूप से मरेगा और पुनर्जन्म लेगा।",
          verse:
            "अशोच्यानन्वशोचस्त्वं प्रज्ञावादांश्च भाषसे।\nगतासूनगतासूंश्च नानुशोचन्ति पण्डिता:।।11।। ",
        },
        {
          id: 12,
          ques: "यदि आत्मा अमर है तो हमें शोक क्यों होता है?",
          ans: "श्री कृष्ण कहते हैं कि हम अपनी अज्ञानता के कारण दुःखी होते हैं और शोक करते हैं। ज्ञान के अभाव के कारण हम समझते हैं कि दिवंगत के साथ हमने उस व्यक्ति को सदा के लिए खो दिया है। किंतु सत्य यही है कि आत्मा न ही किसी को मार सकती है और न ही इसे मारा जा सकता है। जिस प्रकार कोई व्यक्ति पुराने कपड़ों को उतारकर नए कपड़े पहनता है, उसी प्रकार मृत्यु होने पर आत्मा अपने पुराने शरीर को त्यागकर नया शरीर धारण करती है। ",
          verse:
            "य एनं वेत्ति हन्तारं यश्चैनं मन्यते हतम्।\nभौ तौ न विजानीतो नायं हन्ति न हन्यते।।19।।",
        },
        {
          id: 13,
          ques: "मैं अपने क्रोध और आवेश से कैसे मुक्ति पाऊँ?",
          ans: "श्री कृष्ण कहते कि यदि हम लगातार किसी पदार्थ के बारे में सोचते रहते हैं तो हम उसके प्रति आसक्त होने लगते हैं। ऐसे पदार्थ के साथ आसक्त होने से उसके प्रति हमारी इच्छाएँ बढ़ने लगती हैं, और जब वो इच्छाएँ पूरी नहीं होती तो हम क्रोधित होते हैं। इसलिए, क्रोध से मुक्ति पाने के लिए हमें सांसारिक विषयों के बारे में विचार करना रोकना चाहिए। ",
          verse:
            "ध्यायतो विषयान्पुंस: सङ्गस्तेषूपजायते।\nसङ्गात्सञ्जायते काम: कामात्क्रोधोऽभिजायते।।62।।",
        },
        {
          id: 14,
          ques: "आज के तनावपूर्ण जीवन में मानसिक शांति कैसे मिल सकती है?",
          ans: "श्री कृष्ण कहते कि जिस प्रकार विभिन्न नदियों का जल लगातार समुद्र में गिरते रहने के बाद भी समुद्र उससे प्रभावित नहीं होता, उसी तरह जो व्यक्ति अपने आसपास अनेक अभीष्ट पदार्थों के उपलब्ध होने के बाद भी उनकी ओर आकर्षित नहीं होता, उसे मानसिक शांति मिलती है, न कि उस व्यक्ति को जो उन इच्छाओं को पूरी करने के प्रयास करने लगता है। जो व्यक्ति लालच, अधिकार और अहंकार की भावनाओं से मुक्त रहता है उसे सम्पूर्ण शांति मिलती है। ",
          verse:
            "आपूर्यमाणमचलप्रतिष्ठं समुद्रमाप: प्रविशन्ति यद्वत्।\nतद्वत्कामा यं प्रविशन्ति सर्वे स शान्तिमाप्नोति न कामकामी।।70।।",
        },
        {
          id: 15,
          ques: "मानव-मन इतना व्यग्र, अशांत और चंचल होता है कि इसे एकाग्र करना अक्सर अत्यधिक कठिन हो जाता है। इसका नियंत्रण कैसे किया जा सकता है? ",
          ans: "श्री कृष्ण कहते हैं, यह सत्य है कि मानव-मस्तिष्क अत्यधिक व्यग्र, अशांत और चंचल होता है। लेकिन नियमित अभ्यास और वैराग्य से हम आसानी से इसे नियंत्रित कर सकते हैं। \nहम देखते हैं कि मन अपनी पसंद के पदार्थों की ओर भागता है, उन पदार्थों की ओर जिनकी ओर आकर्षित होने की आदत उसे अतीत से बनी रहती है। इस तरह की आसक्तियों को हटाने से मस्तिष्क का अनावश्यक घूमना रुक जाता है। ",
          verse:
            "असंशयं महाबाहो मनो दुर्निग्रहं चलम्।\nअभ्यासेन तु कौन्तेय वैराग्येण च गृह्यते।।35।।",
        },
        {
          id: 16,
          ques: "व्यावसायिक और निजी जीवन में अनेक सफलताएँ पाने के बाद भी मुझे असंतोष क्यों रहता है?",
          ans: "श्री कृष्ण कहते हैं, यह हमारी आंतरिक कमज़ोरी और असुरक्षा की भावना है जो हमें इच्छाओं, लालच और घृणा के चक्र में बाँधे रहती है। दूसरी ओर, वे लोग जिनमें न कोई आकांक्षाएँ होती है और न ही किसी से घृणा वे हर प्रकार के द्वैत भाव से मुक्त होते हैं और भौतिक ऊर्जा के बंधनों से आसानी से मुक्त हो जाते हैं। अपने कर्मों एवं उनके परिणमों से आसानी से विरक्त हो पाने के कारण ऐसे कर्मयोगियों किसी भी परिस्थिति में निराश नहीं होते हैं और इस प्रकार वे हर परिस्थिति में संतुष्ट रहते हैं। ",
          verse:
            "ज्ञेय: स नित्यसंन्यासी यो न द्वेष्टि न काङ्क्षति।\nनिर्द्वन्द्वो हि महाबाहो सुखं बन्धात्प्रमुच्यते।।3।।",
        },
        {
          id: 17,
          ques: "सारे सांसारिक सुख प्राप्त होने के बाद भी मैं दुःखी क्यों रहता हूँ?",
          ans: "श्री कृष्ण कहते हैं, आपकी व्यथा का कारण तमस गुणों की प्रधानता है। \nमानव स्वभाव में तीन प्रकार के गुण होते हैं - सत्त्व, रजस और तमस्।\nइन तीनों में सत्त्व श्रेष्ठ है, क्योंकि यह हमें आंतरिक आनंद और सुख से परिपूर्ण करता है। रजस गुण आत्मा को कर्मों की ओर प्रवृत्त करती है। लेकिन तमस् गुण हमारी बुद्धि को ढँक लेते हैं हमें भ्रमित कर देते हैं। एक बार आप अपने भ्रम के बादलों को हटा देते हैं तो आप अपने गुणों को पहचान पाते हैं और स्वयं को जीवन की व्यथाओं से मुक्त कर पाते हैं। ",
          verse:
            "सत्त्वं सुखे सञ्जयति रज: कर्मणि भारत।\nज्ञानमावृत्य तु तम: प्रमादे सञ्जयत्युत।।9।। (Chapter 14) ",
        },
        {
          id: 18,
          ques: "नेता के लिए समाज के सामने अच्छा उदाहरण प्रस्तुत करना क्यों आवश्यक है?",
          ans: "श्री कृष्ण कहते हैं कि पूरा समाज अपने नेताओं या आदर्शों के जीवन से प्रेरणा लेते हैं। नेता अच्छे उदाहरण प्रस्तुत करके समाज को प्रेरणा देता है, माता-पिता अपनी संतानों को और शिक्षक अपने विद्यार्थियों को। स्वस्थ समाज के निर्माण के लिए इन लोगों का अनिवार्य दायित्त्व बन जाता है कि वे अपने श्रेष्ठ शब्दों, कर्मों एवं चरित्र के उदाहरण प्रस्तुत करके शेष जनता को प्रेरित करते रहें। जब शीर्ष नेता समाज का चेहरा बनते हैं, शेष समाज की नैतिकता, नि:स्वार्थता और आध्यात्मिक शक्ति अपने आप ही उन्नत होती है। लेकिन समय के साथ जब आदर्श नेतृत्व में कमी आने लगती है, शेष समाज के सामने अनुसरण करने के लिए कोई उन्नत मानदंड नहीं होते, फलस्वरूप इसमें आत्म-केंद्रित, नैतिक पतन और आध्यात्मिक गिरावट की स्थिति उत्पन्न होती है।",
          verse:
            "कर्मणैव हि संसिद्धिमास्थिता जनकादय: |\nलोकसंग्रहमेवापि सम्पश्यन्कर्तुमर्हसि ।।20।।\nयद्यदाचरति श्रेष्ठस्तत्तदेवेतरो जन: |\nस यत्प्रमाणं कुरुते लोकस्तदनुवर्तते ।।21।।",
        },
        {
          id: 19,
          ques: "माता-पिता के लिए अपने बच्चों के सामने अच्छा उदाहरण प्रस्तुत करना क्यों आवश्यक है?",
          ans: "श्री कृष्ण कहते हैं कि पूरा समाज अपने नेताओं या आदर्शों के जीवन से प्रेरणा लेते हैं। नेता अच्छे उदाहरण प्रस्तुत करके समाज को प्रेरणा देता है, माता-पिता अपनी संतानों को और शिक्षक अपने विद्यार्थियों को। स्वस्थ समाज के निर्माण के लिए इन लोगों का अनिवार्य दायित्त्व बन जाता है कि वे अपने श्रेष्ठ शब्दों, कर्मों एवं चरित्र के उदाहरण प्रस्तुत करके शेष जनता को प्रेरित करते रहें। जब शीर्ष नेता समाज का चेहरा बनते हैं, शेष समाज की नैतिकता, नि:स्वार्थता और आध्यात्मिक शक्ति अपने आप ही उन्नत होती है। लेकिन समय के साथ जब आदर्श नेतृत्व में कमी आने लगती है, शेष समाज के सामने अनुसरण करने के लिए कोई उन्नत मानदंड नहीं होते, फलस्वरूप इसमें आत्म-केंद्रित, नैतिक पतन और आध्यात्मिक गिरावट की स्थिति उत्पन्न होती है।",
          verse:
            "कर्मणैव हि संसिद्धिमास्थिता जनकादय: |\nलोकसंग्रहमेवापि सम्पश्यन्कर्तुमर्हसि ।।20।।\nयद्यदाचरति श्रेष्ठस्तत्तदेवेतरो जन: |\nस यत्प्रमाणं कुरुते लोकस्तदनुवर्तते ।।21।।",
        },
        {
          id: 20,
          ques: "शिक्षकों के लिए अपने शिष्यों के सामने अच्छा उदाहरण प्रस्तुत करना क्यों आवश्यक है?",
          ans: "श्री कृष्ण कहते हैं कि पूरा समाज अपने नेताओं या आदर्शों के जीवन से प्रेरणा लेते हैं। नेता अच्छे उदाहरण प्रस्तुत करके समाज को प्रेरणा देता है, माता-पिता अपनी संतानों को और शिक्षक अपने विद्यार्थियों को। स्वस्थ समाज के निर्माण के लिए इन लोगों का अनिवार्य दायित्त्व बन जाता है कि वे अपने श्रेष्ठ शब्दों, कर्मों एवं चरित्र के उदाहरण प्रस्तुत करके शेष जनता को प्रेरित करते रहें। जब शीर्ष नेता समाज का चेहरा बनते हैं, शेष समाज की नैतिकता, नि:स्वार्थता और आध्यात्मिक शक्ति अपने आप ही उन्नत होती है। लेकिन समय के साथ जब आदर्श नेतृत्व में कमी आने लगती है, शेष समाज के सामने अनुसरण करने के लिए कोई उन्नत मानदंड नहीं होते, फलस्वरूप इसमें आत्म-केंद्रित, नैतिक पतन और आध्यात्मिक गिरावट की स्थिति उत्पन्न होती है। ",
          verse:
            "कर्मणैव हि संसिद्धिमास्थिता जनकादय: |\n लोकसंग्रहमेवापि सम्पश्यन्कर्तुमर्हसि ।।20।।\nयद्यदाचरति श्रेष्ठस्तत्तदेवेतरो जन: |\nस यत्प्रमाणं कुरुते लोकस्तदनुवर्तते ।।21।।",
        },
        {
          id: 21,
          ques: "हमें ज्ञान प्राप्त करने की क्या आवश्यकता है?",
          ans: "श्री कृष्ण कहते हैं, ज्ञान में शक्ति होती है कि वह व्यक्ति की शुद्धि और उसका उन्नयन करके उसे मुक्त करे और परमात्मा के साथ जोड़ सके। यह परम उदात्त और शुद्ध होता है। ज्ञान प्राप्त करने के प्रयास में सैद्धांतिक जानकारी और व्यावहारिक ज्ञान के बीच के अंतर को समझने की आवश्यकता होती है। जब कोई व्यक्ति अर्जित ज्ञान को व्यवहार में लाता है, तो वह केवल वास्तविक अर्थों में ज्ञान प्राप्त करता है।",
          verse:
            "श्रद्धावान् लभते ज्ञानं तत्पर: संयतेन्द्रिय:।\nज्ञानं लब्ध्वा परां शान्तिमचिरेणाधिगच्छति।।39।।",
        },
        {
          id: 22,
          ques: "छल-कपाट से भरे इस संसार में हम कैसे व्यक्ति को अपना सर्वोत्तम मित्र एवं मार्गदर्शक समझकर उसपर विश्वास कर सकते हैं? ",
          ans: "श्री कृष्ण कहते हैं, हम स्वयं अपने सबसे अच्छे मित्र और मार्गदर्शक होते हैं। अपने मस्तिष्क की शक्ति के साथ हम अप आत्मिक उत्थान कर सकते हैं और अपना पतन नहीं होने देते। चूँकि हमारा मस्तिष्क हमारा सबसे अच्छा मित्र भी हो सकता है और शत्रु भी, अतः अपने उत्थान और पतन के लिए स्वयं हम ही उत्तरदायी होते हैं। ",
          verse:
            "उद्धरेदात्मनात्मानं नात्मानमवसादयेत्।\nआत्मैव ह्यात्मनो बन्धुरात्मैव रिपुरात्मन:।।5।।",
        },
        {
          id: 23,
          ques: "आधुनिक जीवन में अधिकांश लोग एक-दूसरे के साथ विश्वासघात करते हैं। ऐसी अपरिस्थिति में हमें कोई विश्वसनीय व्यक्ति कैसे मिल सकता है?",
          ans: "श्री कृष्ण कहते हैं, विश्वसनीय व्यक्ति एक योगी के समान होता है, जो हर व्यक्ति पर पक्षपात रहित भाव रखता है, चाहे वे उसके शुभ-चिंतक हों, मित्र या शत्रु हों, पवित्र या पापी हों। ऐसा व्यक्ति मित्रों, शत्रुओं और संबंधियों के साथ एक-समान भाव के साथ रहता है, और धार्मिक एवं अधम के साथ निष्पक्ष रहे। ",
          verse:
            "सुहृन्मित्रार्युदासीनमध्यस्थद्वेष्यबन्धुषु।\nसाधुष्वपि च पापेषु समबुद्धिर्विशिष्यते।।9।।",
        },
        {
          id: 24,
          ques: "वर्तमान समय में लगातार बढ़ रहे साम्प्रदायिक द्वेष के भाव की परिस्थिति से हम कैसे निपटें? ",
          ans: "श्री कृष्ण कहते हैं, वास्तविक ज्ञानी कभी भी इस तरह के छुद्र विषयों पर संघर्ष नहीं करते।\nवास्तविक ज्ञानी अपने परम ज्ञान की आँखों से प्रत्येक जीव को एक ही दृष्टि से देखते हैं, चाहे वह ब्राह्मण हो, गाय हो, हाथी या कुत्ता हो या फिर पशु-भक्षी व्यक्ति हो।\nदेवीय ज्ञान से प्राप्त दृष्टि सामान्य नेत्रों से अलग होती है। ज्ञान से संपन्न भक्त को सभी जीवों में ईश्वर की सत्ता दिखाई देती है, अतः वे स्वभाव से देवीय होते हैं। ",
          verse:
            "विद्याविनयसम्पन्ने ब्राह्मणे गवि हस्तिनि।\nशुनि चैव श्वपाके च पण्डिता: समदर्शिन:।।18।।",
        },
        {
          id: 25,
          ques: "ईश्वर कौन है?",
          ans: "श्री कृष्ण कहते हैं, परम सत्ता, यानी मैं, जो सभी जीवों के हृदय में विराजमान है। वही मैं सभी जीवों का आदि, मध्य और अंत हूँ। \nईश्वर आत्मा से दूर नहीं - वास्तव में वह किसी भी अन्य पदार्थ या सत्ता से अधिक निकट है। हमारी आत्मा में ईश्वर का ही वास है। \nभीतर विराजा वह ईश्वर हमें चेतना की शक्ति और आत्मा की शाश्वतता देता है। यदि वह अपनी शक्तियों को घटा देता है तो हमारी आत्मा क्षीण होकर नष्ट हो जाएगी। \nइस तरह से हम आत्माएँ शाश्वत और अविनाशी अपनी स्वयं की शक्तियों के कारण नहीं हैं, बल्कि इसलिए हैं क्योंकि वह अविनाशी एवं शाश्वत ईश्वर हमारे भीतर विराजा है, और हमें शक्तियाँ प्रदान कर रहा है। \nहमारी आत्मा ईश्वर का शरीर है, वही हमारी आत्मा की आत्मा है। ",
          verse:
            "अहमात्मा गुडाकेश सर्वभूताशयस्थित:।\nअहमादिश्च मध्यं च भूतानामन्त एव च।।20।।",
        },
        {
          id: 26,
          ques: "मानव शरीर त्यागने के बाद आत्मा कहाँ जाती है?",
          ans: "श्री कृष्ण कहते हैं, जो मेरा यानी ईश्वर का ध्यान करते हुए देह त्यागते हैं वे मेरे पास आते हैं। \nइसलिए जो व्यक्ति अपनी मृत्यु के समय ईश्वर का ध्यान करते हैं वे उन्हीं की शरण में पहुँचते हैं, उन्हें प्राप्त करते हैं और ईश्वरीय-गुणों से व्याप्त हो जाते हैं।",
          verse:
            "अन्तकाले च मामेव स्मरन्मुक्त्वा कलेवरम्।\nय: प्रयाति स मद्भावं याति नास्त्यत्र संशय:।।5।।",
        },
        {
          id: 27,
          ques: "क्या ब्रह्मांड को कोई नियंत्रित करता है?",
          ans: "श्री कृष्ण कहते हैं, हाँ, वह है सर्वोच्च परम सत्ता। वह सर्वव्यापी है। वही ऐसे आधार हैं जिंपर यह संपूर्ण सृष्टि आधारित है। वही सृष्टि-कर्त्ता, पालन-कर्त्ता और विनाशक हैं। जिस तरह धागे में पिरोए गए मोती अपने स्थान पर इधर-उधर घूम सकते हैं, उसी तरह ईश्वर ने सभी आत्माओं को उनकी इच्छानुसार कर्म करने की स्वतंत्रता दि है। फिर भी उनके अस्तित्त्व ईश्वर से ही जुड़े हैं। \nलेकिन सत्त्व, रजस और तमस से उत्पन्न माया के कारण इस संसार के मनुष्य उस परम सत्ता को पहचानने में असमर्थ होते हैं, जो अविनाशी और शाश्वत है। ",
          verse:
            "मत्त: परतरं नान्यत्किञ्चिदस्ति धनञ्जय।\nमयि सर्वमिदं प्रोतं सूत्रे मणिगणा इव।।7।।",
        },
      ],
      home: "होम",
      aboutus: "विषय में",
      detail: "अपना विवरण दें",
      name: "नाम दर्ज करें",
      gender: "लिंग चुनें",
      male: "पुरुष",
      female: "स्त्री",
      other: "अन्य",
      age: "  आयु दर्ज करें",
      next: "अगला",
      restart: "पुनः आरंभ करें",
      tap: "अपना प्रश्न यहां बोलने के लिए टैप करके रखें",
      ask: "अपने जीवन से संबंधित कोई भी प्रश्न पूछें",
      ques: "सवाल",
      enter: "जारी रखने के लिए नीचे अपना विवरण दर्ज करें",
      heading1:
        "गीता जीवन-शैली के प्रबंधन की एक प्राचीन स्रोत है जो हमारा मार्गदर्शन करती है, हमें प्रेरित करती है और हमारे भीतर सकारात्मक परिवर्तन लाकर हमें कर्मों की ओर प्रवृत्त करती है।",
      heading2:
        "गीता प्रथम गुरु और प्रथम मनोवैज्ञानिक श्रीकृष्ण का गीत है, जिसके माध्यम से वह अर्जुन के धुँधलाए विचारों में स्पष्टता लाए और उन्हें कर्म के आनंदमय मार्ग की ओर प्रवृत्त किया।",
      heading3: "Askgita.ai",
      heading4:
        "ऐसा प्रयास है, जिसके अंतर्गत वर्तमान पीढ़ी का मार्गदर्शन करने और उनकी जीवन-शैली में परिवर्तन लाने के लिए गीता के सुनहरे संदेशों का उपयोग किया गया है।",
      heading5:
        "यह ऐप/पोर्टल न केवल पूर्व निर्धारित प्रश्नों के व्यावहारिक उत्तर प्रदान करता है, बल्कि यह आवाज़ तथा/अथवा लिखित निर्देशों पर भी काम करता है।",
      heading6: "हमारे विषय में",
    },
  },
};

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") == "english" ? "en" : "hi", //default language
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
