import React from "react";
import { useTranslation } from "react-i18next";

export const AboutUs = () => {
  const [t, i18n] = useTranslation();
  return (
    <div
      style={{
        marginTop: "10vh",
        textAlign: "center",
        padding: "0 5vw",
        zIndex: "100",
        color: "white",
      }}
    >
      <h1
        style={{
          color: "rgb(244, 172, 11)",
        }}
      >
        {t("heading6")}
      </h1>
      {t("heading1")}
      <br />
      <br />
      {t("heading2")}
      <br />
      <br />
      <span
        style={{
          fontWeight: "bold",
          display: "inline-flex",
          textTransform: "uppercase",
        }}
      >
        {t("heading3")}{" "}
      </span>{" "}
      {t("heading4")}
      <br />
      <br />
      {t("heading5")}
    </div>
  );
};
