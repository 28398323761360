import { React, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import slpashBgNew from "static/splashBgN.png";
import BackgroundKrishna from "static/BackgroundKrishna.svg";
import BackgroundImg from "static/Background.svg";
import { useHistory } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { Footer } from "components/Footer/Footer";
import LangBtn from "static/lang.png";
import { useTranslation } from "react-i18next";
import { Offcanvas } from "react-bootstrap";

const Root = styled("div")(({ theme }) => ({
  backgroundColor: "black",
  ...theme.flexColumnCenter,
  backgroundImage: `url(${slpashBgNew})`,
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  // zIndex:-1
}));

const BackGround = styled("div")(({ theme }) => ({
  backgroundImage: `url(${BackgroundKrishna})`,
  justifyContent: "center",
  height: "100%",
  width: "100%",
  opacity: 0.5,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  position: "absolute",
  backgroundSize: "cover",
  "@media (min-width: 780px)": {
    backgroundImage: `url(${BackgroundImg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
}));

function RootLayout({ children }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showMenu, setShowMenu] = useState();
  const [t, i18n] = useTranslation();

  useEffect(() => {}, [useHistory()]);
  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const history = useHistory();
  const pageNavigate = (page) => {
    console.log("about");
    setDrawerOpen(!drawerOpen);
    history.push(page);
  };
  const changeLanguage = () => {
    if (i18n.language == "en") i18n.changeLanguage("hi");
    else i18n.changeLanguage("en");
  };

  window.ondragstart = function () {
    return false;
  };

  useEffect(() => {
    history.listen((location, rex) => {
      if (location.pathname != "/") setShowMenu(true);
      else setShowMenu(false);

      // do something on route change
      // for my example, close a drawer
    }),
      history.location.pathname != "/" ? setShowMenu(true) : setShowMenu(false);
  }, []);
  return (
    <>
      <Root>
        <BackGround />
        {children}
      </Root>
      <button
        onClick={handleShow}
        className="hamburger"
        style={{ zIndex: "100" }}
      >
        <i className="fa fa-bars"></i>
      </button>
      <Offcanvas show={show} placement="end" onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="links">
            <p
              onClick={() => {
                handleClose();
                pageNavigate("/");
              }}
              className="ham-link"
            >
              {t("home")}
            </p>
            <p
              onClick={() => {
                handleClose();
                pageNavigate("/aboutus");
              }}
              className="ham-link"
            >
              {t("aboutus")}
            </p>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default RootLayout;
