import { React, useState, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import GitaLogo from "static/GitaLogo.svg";
import { animated, useSpring } from "@react-spring/web";
import "../../HomePage.css";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Footer } from "components/Footer/Footer";

const reactRouterUrl = "https://github.com/ReactTraining/react-router";
const reactfireUrl = "https://github.com/FirebaseExtended/reactfire";

function Home() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showMenu, setShowMenu] = useState();
  const [t, i18n] = useTranslation();

  useEffect(() => {}, [useHistory()]);
  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const history = useHistory();
  const pageNavigate = (page) => {
    console.log("about");
    setDrawerOpen(!drawerOpen);
    history.push(page);
  };
  const changeLanguage = () => {
    if (i18n.language == "en") i18n.changeLanguage("hi");
    else i18n.changeLanguage("en");
  };

  window.ondragstart = function () {
    return false;
  };

  useEffect(() => {
    // i18n.changeLanguage("en");
    history.listen((location, rex) => {
      if (location.pathname != "/") setShowMenu(true);
      else setShowMenu(false);

      // do something on route change
      // for my example, close a drawer
    }),
      history.location.pathname != "/" ? setShowMenu(true) : setShowMenu(false);
  }, []);
  const springs = useSpring({
    from: { opacity: 0, width: 0 },
    to: { opacity: 1, width: 500 },
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // Go to Ask Gita page after 3 second
  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log("Going to Login")
  //     window.location.href = "/askgita";
  //   }, 3000);
  // }, [])

  const goToAskGitaPage = () => {
    history.push("/language");
  };

  return (
    // <RootLayout>

    <>
      <button
        onClick={handleShow}
        className="hamburger"
        style={{ zIndex: "100" }}
      >
        <i className="fa fa-bars"></i>
      </button>
      <Offcanvas show={show} placement="end" onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="links">
            <p
              onClick={() => {
                handleClose();
                pageNavigate("/");
              }}
              className="ham-link"
            >
              {t("home")}
            </p>
            <p
              onClick={() => {
                handleClose();
                pageNavigate("/aboutus");
              }}
              className="ham-link"
            >
              {t("aboutus")}
            </p>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          background: "transparent",
          zIndex: "1",
        }}
        onClick={goToAskGitaPage}
      >
        <div
          onClick={goToAskGitaPage}
          style={{
            textAlign: "center",
            bottom: "10%",
            position: "fixed",
            left: "20%",
            right: "20%",
            zIndex: "-1",
            fontFamily: "Aparajita",
            fontStyle: "normal",
            fontWeight: "100",
            fontSize: "40.5934px",
            lineHeight: "56px",
            textAlign: "center",
            color: "#F4AC0B",
            cursor: "pointer",
            textShadow: "4px 3px 1px rgba(0, 0, 0, 0.17)",
          }}
        >
          {/* <AskGitaButton>गीता से पूछें</AskGitaButton>
           */}
          <animated.img src={GitaLogo} style={{ ...springs }} />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
